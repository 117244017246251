@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-font-family: 'Poppins', sans-serif;
  --white-color-text: #ffffff
}

* {
  font-family: var(--main-font-family);
}

html, #root {
  height: 100% !important;
}

html {
  min-height: 100%;
  /* overflow: hidden; */
}

#root {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  /* overflow: auto; */
}

body {
  overflow: auto;
  height: 100% !important;
  margin: 0;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f1c29;
  background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
  background-image: linear-gradient(to right, #0f1c29, #141E30);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.08);
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(105, 105, 105, 0.5);
  border-radius: 0.2rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:any-link {
  text-decoration: none;
  /* color: inherit; */
}
a:-webkit-any-link:active {
  color: inherit;
}
.text-link.underline:hover{
  font-weight: 600;
  text-decoration: underline;
}
input:-internal-autofill-selected{
  background-color: inherit !important;
  color: inherit !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px #122435 inset !important;
    -webkit-box-shadow: 0 0 0 100px #122435 inset !important;
    -webkit-text-fill-color: white !important;
    border: none !important;
}
.white-text {
  color: var(--white-color-text);
}
.lowercase {
  text-transform: lowercase;
}
@media (max-width: 1199px){
  body{
    /* overflow-y: hidden; */
  }
}
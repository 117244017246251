.profile-editable-info {
    margin-top: 1rem;
}

.profile-editable-info .editable-info-options {
    width: 100%;
}

.profile-editable-info .editable-info-options .editable-info-collapse > div {
    padding-top: 1rem;
}

.profile-editable-info .editable-info-options .editable-info-radio {
    width: fit-content;
}

.profile-editable-info .editable-info-options .editable-info-radio > label > span:nth-child(1) {
    margin: 0 1rem;
}

.profile-editable-info .editable-info-options .editable-info-radio > label > span:nth-child(2) {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
}

.profile-editable-info .editable-info-options > div {
    margin: 1rem 0;
}

.profile-editable-info .editable-info-options > div:first-child {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.profile-editable-info .editable-info-options .editable-info-title > div {
    display: flex;
}

.profile-editable-info .editable-info-options > div:last-child {
    margin-bottom: 0;
}

.profile-editable-info .editable-info-options .editable-info-description {
    margin: 0;
}   
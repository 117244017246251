div.section-home{
    /* background-color: #282c34; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    font-family: var(--main-font-family) !important;
    /* background-image: #3a6186;
    background-image: -webkit-linear-gradient(to right, #3a6186, #89253e); 
    background-image: linear-gradient(to right, #3a6186, #89253e); */
    background-image: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.section-home::-webkit-scrollbar {
    width: 0.5rem;
}
.section-home::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-home::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

div.section-home-body{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1.title-home, h5.text-body{
    font-family: 'Poppins', sans-serif !important;
}

.home-grid-container{
    color: white;
}

h1.title-home{
    font-weight: 600;
}

@media (min-width: 0px) and (max-width: 576px) {
    h1.title-home{
        font-size: 2.2rem;
    }
    h5.text-body{
        font-size: 1.2rem;
    }
}
.profile-username-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 599px) {
    .profile-username-actions button:last-child{
        margin-top: 1rem;
    }
}
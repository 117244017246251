.section-not-found{
    /* background-color: #282c34; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-color: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.usernotfound-grid-container{
    min-width: 250px;
    inline-size: 100%;
    overflow-wrap: anywhere;
}

h3.title-not-found{
    font-family: var(--main-font-family) !important;
    font-weight: 600;
    color: white;
}

@media (min-width: 0px) and (max-width: 576px) {
    h3.title-not-found{
        font-size: 2rem;
    }
}
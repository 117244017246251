.menu-user-float span.MuiSpeedDialAction-staticTooltipLabel {
    text-wrap: nowrap;
}

.menu-divider {
    padding: 0 1rem;
}

.menu-divider hr {
    border-color: rgba(15, 28, 41, 0.1);
}

.menu-user-drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #0f1c29;
    text-align: center;
}

.menu-user-drawer  .menu-fabs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
}

.menu-user-drawer button.menu-user-action {
    background-color: transparent;
    color: #e8e7e7;
    border: 2px solid #e8e7e7;
}

.menu-user-drawer button.menu-user-action:hover {
    background-color: transparent;
    color: #e8e7e7;
    border: 2px solid #e8e7e7;
}

.menu-user-drawer .menu-user-action svg {
    margin-right: 0.5rem;
}


/* .menu-user-mobile {
    color: black;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
} */

@media screen and (min-width: 768px) {
    .menu-user-float {
        display: flex;
    }
    .menu-user-drawer {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 767px) {
    .menu-user-float {
        display: none;
        visibility: hidden;
    }
    .menu-user-drawer {
        display: flex;
        visibility: visible;
    }
}
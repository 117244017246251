header.section-nav{
    background-color: transparent;
    box-shadow: none;
}

header.section-nav h6{
    font-family: var(--main-font-family) !important;
    font-weight: 700;;
}

.iconProfileMenu{
    margin-right: 0.5rem;
}

button.login-btn{
    margin-right: 1rem;
}

a.linkButton{
    display: flex;
    align-items: center;
}

@media (max-width: 1199px){
    header.section-nav{
        padding: 0.2rem 0;
    }
}
.editable-link-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;
}

.editable-link-item:first-child {
    margin-top: 0;
}

.editable-link-container-dnd{
    width: 2.5rem;
}

.editable-link-dnd {
    margin: 0 0.5rem 0 0;
    min-width: 30px;
}

.editable-link-container-right {
    width: calc(100% - 2.5rem);
    max-width: calc(100% - 2.5rem);
}

div.editable-link-textfield {
    width: 100%;
    margin-bottom: 1rem;
    /* margin-right: 1rem; */
}

.editable-link-container-right-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.editable-link-container-right-bottom .MuiInputBase-root {
    max-width: calc(100% - 49px);
}

#editable-link-select {
    max-width: 13rem;
    width: 13rem;
}
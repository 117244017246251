.profile-editable-theme {
    margin-top: 0;
}

.editable-theme-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editable-theme-options .button-theme-option {
    display: flex;
}

.editable-theme-options .container-button-theme-option {
    display: flex;
    align-items: center;
}

.editable-theme-options .container-button-theme-option.border-options > .button-theme-option, .editable-theme-options .container-button-theme-option > h6 {
    margin-right: 1rem;
}

.editable-theme-options .container-button-theme-option .button-theme-option {
    width: 3.5rem;
    min-width: 3.5rem;
    height: 2rem;
    border-radius: 4px;
    border: 1px solid #e8e7e7;
    cursor: pointer;
}

.editable-theme-options .container-button-theme-option .button-theme-color-picker {
    position: absolute;
    top: 28;
    right: 0;
    left: 0;
    z-index: 1;
    border: 0;
}

#editable-theme-select-border {
    max-width: 7rem;
    width: 7rem;
}

div.MuiPopover-paper::-webkit-scrollbar {
    width: 0.3rem;
}
div.MuiPopover-paper::-webkit-scrollbar-track {
    background-color: rgb(47 63 78);
}
div.MuiPopover-paper::-webkit-scrollbar-thumb {
    background-color: rgb(63 78 92);
    border-radius: 0.2rem;
}

.popover-theme-option > div.MuiPopover-paper {
    overflow: inherit;
}

@media screen and (max-width: 768px) {
    .editable-theme-options .container-button-theme-option {
        flex-wrap: wrap;
        margin-top: 0.5rem;
    }
    .editable-theme-options .container-button-theme-option > h6, .editable-theme-options .container-button-theme-option > button, .editable-theme-options .container-button-theme-option > div  {
        margin-top: 0.5rem;
    }
}

.section-links{
    /* background-color: #282c34; */
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

div.section-links-body{
    height: 100%;
}

.section-links::-webkit-scrollbar {
    width: 0.5rem;
}
.section-links::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-links::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

.user-grid-container{
    min-width: 250px;
    inline-size: 100%;
    overflow-wrap: anywhere;
    height: 100%;
}

div.user-grid-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    gap: 1.5rem;
}

div.user-info-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

h3.user-name-text{
    font-family: var(--main-font-family) !important;
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 0;
} 

p.user-body-text{
    font-family: var(--main-font-family) !important;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 0;
}

div.user-links-container {
    padding: 0 5rem;
}

@media screen and (max-width: 600px) {
    h3.user-name-text{
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    div.user-links-container {
        padding: 0 4rem;
    }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
    div.user-links-container {
        padding: 0 1.5rem;
    }
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    div.user-links-container {
        padding: 0 1.5rem;
    }
}
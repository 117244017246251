.profile-editable-links, .editable-links-theme-options {
    margin-top: 1rem;
}

.editable-links-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.profile-grid-editable-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
}

.profile-grid-editable-links > div {
    width: 100%;
}

.profile-grid-editable-links > div > div {
    margin-bottom: 1rem;
}

.profile-grid-editable-links > div > div:last-child {
    margin-bottom: 0;
}

.editable-links-empty-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.editable-links-stack {
    width: 100%;
}

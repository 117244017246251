.editable-link-item{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #233343;
    border-radius: 8px 4px 4px 8px;
    padding: 1rem 1rem 1rem 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.06);
}

.editable-link-item .editable-link-container-dnd{
    display: flex;
}

.button-link{
    border-radius: 2rem !important;
}

.text-link{
    font-family: var(--main-font-family);
    font-weight: 600;
}

.disabled-link {
    pointer-events: none;
}
.snackbar-notifications .MuiAlert-icon,
.snackbar-notifications .MuiAlert-message,
.snackbar-notifications .MuiAlert-action {
    color: white;
}

.snackbar-notifications .MuiAlert-action {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div.section-profile{
    /* background-color: #282c34; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    font-family: var(--main-font-family) !important;
    background-color: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.section-profile::-webkit-scrollbar {
    width: 0.5rem;
}
.section-profile::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-profile::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

.section-profile-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.section-profile-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

div.section-profile-container::-webkit-scrollbar {
    width: 0.375rem;
}
div.section-profile-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
div.section-profile-container::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

h3.title-profile, h3.title-profile-skeleton, h5.text-body{
    font-family: 'Poppins', sans-serif !important;
}

div.title-profile-grid-skeleton.MuiGrid-item{
    padding-top: 2rem;
}

.profile-grid-container{
    color: white;
    padding-bottom: 2rem;
}

div.profile-grid-username{
    align-items: baseline;
}

.profile-grid-skeleton > span {
    height: 15rem;
    border-radius: 4px;
}

.profile-grid-skeleton > span:last-child {
    margin-top: 1rem;
}

h3.title-profile, h3.title-profile-skeleton{
    font-weight: 600;
}

div.section-profile-body .section-profile-alert-action{
    display: flex;
    color: white;
    align-items: center;
}

div.section-profile-body .section-profile-alert-action .MuiAlert-message{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

div.section-profile-body .section-profile-alert-action .MuiAlert-action{
    padding-top: 0;
}

a.link-profile-page{
    text-decoration: underline;
}

@media (max-width: 1199px){
    div.section-profile-container{
        /* overflow-y: auto; */
    }
    .profile-grid-container{
        color: white;
        padding-bottom: 0rem;
    }
}

@media (min-width: 576px) and (max-width: 899px) {
    .profile-grid-skeleton > span {
        height: 12rem;
    }
}

@media (min-width: 0px) and (max-width: 576px) {
    h3.title-profile, h3.title-profile-skeleton{
        font-size: 2rem;
    }
    h5.text-body{
        font-size: 1.2rem;
    }
    .profile-grid-skeleton > span {
        height: 10rem;
    }
}
div.section-auth{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-family: var(--main-font-family) !important;
    background-color: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.section-auth::-webkit-scrollbar {
    width: 0.5rem;
}
.section-auth::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-auth::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

div.section-auth-body{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1.title-auth, h5.text-body{
    font-family: 'Poppins', sans-serif !important;
}

.auth-grid-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-box{
    max-width: 25rem;
    width: 100%;
}

.auth-box .auth-paper{
    padding: 2rem 1.5rem;
}

.auth-form-login{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
}

.auth-form-header-title{
    display: flex;
    align-items: center;
}

p.auth-form-header-body{
    margin-top: 1rem;
    text-align: left;
}

button.auth-btn{
    margin-top: 1rem;
}

.section-auth .MuiOutlinedInput-root:hover fieldset{
    border-color: #e8e7e7;
    border-width: 2px !important;
}

.section-auth .MuiOutlinedInput-root fieldset{
    border-color: rgba(255, 255, 255, 0.30);
}

.section-auth .MuiOutlinedInput-root.Mui-focused{
    border-width: 2px;
}

.section-auth .MuiOutlinedInput-root.Mui-error:hover fieldset{
    border-color: #d32f2f;
    border-width: 2px;
}

.section-auth #email, .section-auth #password, .section-auth .MuiInputLabel-root, .section-auth .MuiInputLabel-root #email-label, .section-auth .MuiInputLabel-root #password-label{
    color: #e8e7e7;
}

.section-auth #password {
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
}

.section-auth .auth-form-login div.MuiInputAdornment-root button {
    left: -0.2rem;
}

.section-auth .MuiInputLabel-root.Mui-error #email-label, .section-auth .MuiInputLabel-root.Mui-error #password-label{
    color: #d32f2f;
}

.auth-request-error{
    color: #d32f2f;
}

p.auth-forgot-password {
    margin-top: 0.8rem;
    align-self: flex-start;
}

span.accepted-terms-privacy {
    margin-top: 0.8rem;
    align-self: flex-start;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .auth-box .auth-paper {
        padding: 1.5rem 1rem;
    }
}

@media (min-width: 0px) and (max-width: 576px) {
    h1.title-auth{
        font-size: 2.2rem;
    }
    h5.text-body{
        font-size: 1.2rem;
    }
}


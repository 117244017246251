.profile-preview-links {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.profile-preview-links .profile-grid-preview-links {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: stretch;
    flex-direction: column;
    padding: 3rem;
}

.profile-preview-links .profile-grid-preview-item {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.profile-preview-links .profile-grid-preview-item .preview-info-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.profile-preview-links .profile-grid-preview-links.empty-preview {
    padding: 1rem 8rem;
}

.profile-preview-links .profile-preview-links-container {
    padding: 0 3rem;
}

h4.profile-preview-username{
    font-family: var(--main-font-family) !important;
    text-align: center;
    font-weight: 500;
    font-size: 1.9rem;
    overflow-wrap: anywhere;
}

p.profile-preview-body-text {
    text-align: center;
}

.preview-empty-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.preview-empty-links h6 {
    text-align: center;
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .profile-preview-links .profile-grid-preview-links{
        padding: 3rem 1.5rem;
    }
    .profile-preview-links .profile-grid-preview-links.empty-preview {
        padding: 1rem 6rem;
    }
    .profile-preview-links .profile-preview-links-container {
        padding: 0 2.5rem;
    }
    .profile-preview-links .preview-empty-links {
        padding: 0 3rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
    .profile-preview-links .profile-grid-preview-links{
        padding: 3rem 6rem;
    }
    .profile-preview-links .profile-grid-preview-links.empty-preview {
        padding: 1rem 6rem;
    }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .profile-preview-links .profile-grid-preview-links{
        padding: 3rem 3rem;
    }
    .profile-preview-links .profile-grid-preview-links.empty-preview {
        padding: 1rem 6rem;
    }
    .profile-preview-links .profile-preview-links-container {
        padding: 0 2.5rem;
    }
}

@media screen and (max-width: 600px) {
    .profile-preview-links .profile-grid-preview-links{
        padding: 3rem 1.5rem;
    }
    .profile-preview-links .profile-grid-preview-links.empty-preview {
        padding: 1rem 2rem;
    }
    h4.profile-preview-username{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .profile-preview-links .profile-grid-preview-links{
        padding: 3rem 1rem;
    }
    .profile-preview-links .profile-preview-links-container {
        padding: 0 1rem;
    }
}
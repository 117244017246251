.section-footer{
    height: 3rem;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.footer-container{
    height: 100%;
}

.footer-social-media, .footer-copyright {
    display: flex;
    align-items: center;
    height: 100%;
}

.toggle-group-language{
    display: flex;
    align-items: center;
}

.toggle-group-language button{
    padding: 0.2rem 0.5rem;
    height: fit-content;
}

/* .footer-developed{
    display: flex;
    align-items: center;
    justify-content: flex-end;
} */

.footer-copyright{
    justify-content: flex-end;
}

.footer-social-media {
    justify-content: flex-start;
}

.footer-copyright p{
    display: flex;
    align-items: center;
    height: 100%;
}

.divider-footer{
    min-height: 0.9375rem;
}

.separate-dot{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

@media (min-width: 0px) and (max-width: 599px) {
    .section-footer{
        height: auto;
    }
    .footer-social-media, .footer-copyright, .footer-developed{
        justify-content: center;
        height: auto;   
    }
    div.footer-copyright{
        margin-top: 0.5rem;
    }
}
div.section-prereset-password{
    /* background-color: #282c34; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    font-family: var(--main-font-family) !important;
    background-color: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.section-prereset-password::-webkit-scrollbar {
    width: 0.5rem;
}
.section-prereset-password::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-prereset-password::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

div.section-prepass-body{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1.title-prepass, h5.text-body{
    font-family: 'Poppins', sans-serif !important;
}

.prepass-grid-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.prepass-box{
    max-width: 25rem;
    width: 100%;
}

.prepass-box .prepass-paper{
    padding: 2rem 1.5rem;
}

.prepass-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prepass-form-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
}

.prepass-form-header-title{
    display: flex;
    align-items: center;
}

p.prepass-form-header-body{
    margin-top: 1rem;
}

/* h1.title-auth{
    font-weight: 600;
} */

button.prepass-btn{
    margin-top: 1rem;
}

.section-prepass .MuiOutlinedInput-root:hover fieldset{
    border-color: #e8e7e7;
    border-width: 2px !important;
}

.section-prepass .MuiOutlinedInput-root fieldset{
    border-color: rgba(255, 255, 255, 0.30);
}

.section-prepass .MuiOutlinedInput-root.Mui-focused{
    border-width: 2px;
}

.section-prepass .MuiOutlinedInput-root.Mui-error:hover fieldset{
    border-color: #d32f2f;
    border-width: 2px;
}

.section-prepass #email, .section-prepass #password, .section-prepass .MuiInputLabel-root, .section-prepass .MuiInputLabel-root #email-label, .section-prepass .MuiInputLabel-root #password-label{
    color: #e8e7e7;
}

.section-prepass #password {
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
}

.section-prepass .prepass-form div.MuiInputAdornment-root button {
    left: -0.2rem;
}

.section-prepass .MuiInputLabel-root.Mui-error #email-label, .section-prepass .MuiInputLabel-root.Mui-error #password-label{
    color: #d32f2f;
}

.prepass-request-error{
    color: #d32f2f;
}

@media (min-width: 0px) and (max-width: 576px) {
    h1.title-prepass{
        font-size: 2.2rem;
    }
    h5.text-body{
        font-size: 1.2rem;
    }
}
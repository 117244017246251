div.section-tc{
    /* background-color: #282c34; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--main-font-family) !important;
    /* background-image: #3a6186;
    background-image: -webkit-linear-gradient(to right, #3a6186, #89253e); 
    background-image: linear-gradient(to right, #3a6186, #89253e); */
    background-color: #0f1c29;
    background-image: -webkit-linear-gradient(to bottom, #0f1c29, #141E30); 
    background-image: linear-gradient(to right, #0f1c29, #141E30);
}

.section-tc-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section-tc::-webkit-scrollbar {
    width: 0.5rem;
}
.section-tc::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}
.section-tc::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105, 0.5);
    border-radius: 0.2rem;
}

div.section-tc-body{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

h3.title-tc, p.subtitle-body, p.text-body{
    font-family: 'Poppins', sans-serif !important;
}

p.text-body{
    margin-bottom: 1rem;
}

.tc-grid-container, .text-link{
    color: white;
}

h3.title-tc{
    font-weight: 600;
}

@media (min-width: 0px) and (max-width: 576px) {
    h3.title-tc{
        font-size: 2rem;
    }
}